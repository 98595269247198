var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "credit-risk-score" }, [
    _c("div", [
      _vm._v(
        "\n        " + _vm._s(_vm.$t("webview.creditRiskScore.desc")) + "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "notice" }, [
      _c("img", { attrs: { src: require("./img/notice.svg") } }),
      _vm._v(" "),
      _c("div", [
        _vm._v(" " + _vm._s(_vm.$t("webview.creditRiskScore.tips")) + " "),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "score-card" }, [
      _c("img", {
        staticClass: "score-card-picture",
        attrs: { src: require("./img/score-picture.png") },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "score-card-text" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.$t("webview.creditRiskScore.score")) +
            "\n            "
        ),
        _c("div", { staticClass: "score" }, [
          _vm._v("\n                " + _vm._s(_vm.score) + "\n            "),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "btn", on: { click: _vm.finishAlgoScoreAuth } },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("webview.creditRiskScore.btn")) +
            "\n    "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }