<!-- @author ruiwang -->
<!-- @email ruiwang@yangqianguan.com -->
<!-- @date 2020-2-13 10:44:32 -->
<!-- @desc index.vue -->

<template>
    <div class="credit-risk-score">
        <div>
            {{ $t('webview.creditRiskScore.desc') }}
        </div>

        <div class="notice">
            <img src="./img/notice.svg">
            <div> {{ $t('webview.creditRiskScore.tips') }} </div>
        </div>

        <div class="score-card">
            <img
                class="score-card-picture"
                src="./img/score-picture.png"
            >
            <div class="score-card-text">
                {{ $t('webview.creditRiskScore.score') }}
                <div class="score">
                    {{ score }}
                </div>
            </div>
        </div>

        <button
            class="btn"
            @click="finishAlgoScoreAuth"
        >
            {{ $t('webview.creditRiskScore.btn') }}
        </button>
    </div>
</template>

<script type="text/babel">
    import InterFace from 'ssr-common/util/webview/yqg-webview-interface';

    import Loan from 'olacred/common/resource/loan';

    export default {
        name: 'CreditRiskScore',

        data() {
            return {
                score: null
            };
        },

        title() {
            return this.$t('webview.creditRiskScore.title');
        },

        mounted() {
            this.getScore();
        },

        methods: {
            async getScore() {
                const {data: {body}} = await Loan.algoScore();
                if (body) {
                    this.score = body.score;
                }
            },

            async finishAlgoScoreAuth() {
                const {data: {body}} = await Loan.finishAlgoScoreAuth();
                if (body) {
                    this.authSuccess();
                }
            },

            authSuccess() {
                InterFace.callHandler('authSuccess');
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "~olacred/common/style/index";

    .credit-risk-score {
        padding: 10px 25px;
        font-size: 14px;
        line-height: 18px;

        .notice {
            color: #999;
            font-size: 12px;
            display: flex;
            align-items: flex-start;
            margin: 9px auto;

            img {
                width: 12px;
                height: auto;
                margin-right: 5px;
                margin-top: 2px;
            }
        }

        .score-card {
            height: 100px;
            position: relative;
            overflow: hidden;
            border-radius: 8px;
            background: $base-white;
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 6%);

            &-picture {
                position: absolute;
                height: 100px;
                width: auto;
                top: 0;
                right: 0;
            }

            &-text {
                height: 100px;
                padding: 20px;
                box-sizing: border-box;

                .score {
                    color: $primary-orange;
                    font-size: 34px;
                    font-weight: 900;
                    line-height: 42px;
                }
            }
        }

        .btn {
            width: 100%;
            height: 60px;
            margin-top: 30px;
            border-radius: 8px;
            color: $base-white;
            font-size: 18px;
            font-weight: bold;
            background: $primary-orange;
            outline: none;
            border: none;
        }
    }
</style>
