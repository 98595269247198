/*
 * @Author: chengyuzhang
 * @Date: 2020-12-25 18:57:11
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2020-12-25 19:07:27
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    algoScore: {
        url: '/api/loan/algoScore',
        method: 'get'
    },

    finishAlgoScoreAuth: {
        url: '/api/loan/finishAlgoScoreAuth',
        method: 'post'
    }
};

export default Resource.create(api);
